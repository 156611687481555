import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import YouTubePlayer from './YouTubePlayer';

// import Videos from "../res/videolist.json";

const VideoGallery = () => {
    const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isPlayerVisible, setIsPlayerVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const location = useLocation();
  const category = location.pathname === '/' ? 'homelist' : location.pathname.slice(1);

  useEffect(() => {
    const loadVideos = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('/data/videos.json');
        const data = await response.json();
        
        // Получаем видео для текущей категории
        const categoryVideos = data[category] || [];
        setVideos(categoryVideos);
        
        // Сбрасываем выбранное видео при смене категории
        setSelectedVideo(null);
        setIsPlayerVisible(false);
        
        setIsLoading(false);
      } catch (err) {
        setError('Failed to load videos');
        setIsLoading(false);
      }
    };

    loadVideos();
  }, [category]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-full text-red-500">
        {error}
      </div>
    );
  }

  const handleVideoSelect = (video) => {
    setSelectedVideo(video);
    if (!isPlayerVisible) {
      setIsPlayerVisible(true);
    }
  };

  return (
    <div className="w-full h-full flex flex-col">
      {/* Контейнер для плеера и списка */}
      <div className={`
        w-full flex flex-col
        transition-all duration-500 ease-in-out
        ${isPlayerVisible ? 'h-full' : 'h-full'}
      `}>
        {/* Плеер */}
        <div className={`
          transition-all duration-500
          ${isPlayerVisible ? 'h-[70%]' : 'h-0'}
          overflow-hidden
        `}>
          {isPlayerVisible && selectedVideo && (
            <div className="w-full h-full">
            <YouTubePlayer 
              key={selectedVideo.videoId}
              videoId={selectedVideo.videoId}
              title={selectedVideo.title}
              onVideoSelect={handleVideoSelect}
            />
          </div>
          )}
        </div>

        {/* Список видео */}
        <div className={`
          w-full
          transition-all duration-500
          ${isPlayerVisible ? 'h-[30%]' : 'h-full'}
        `}>
          <div className="h-full relative">
            <div className="absolute inset-0 overflow-x-auto overflow-y-hidden">
              <div className="min-w-full h-full p-4">
                <div 
                  style={{ width: 'max-content' }} 
                  className={`
                    gap-4
                    ${isPlayerVisible 
                      ? 'flex flex-nowrap items-center' 
                      : 'grid grid-rows-2 auto-cols-[250px] grid-flow-col'
                    }
                    h-full
                  `}
                >
                  {videos.map((video) => (
                    <div
                      key={video.id}
                      onClick={() => handleVideoSelect(video)}
                      className={`
                        cursor-pointer
                        transition-all duration-300 shadow-lg shadow-indigo-500/40
                        ${isPlayerVisible 
                          ? 'w-[200px] h-[120px]' 
                          : 'w-[250px] h-[160px]'
                        }
                        relative
                        rounded-lg
                        overflow-hidden
                        ${selectedVideo?.id === video.id 
                          ? 'ring-2 ring-blue-500' 
                          : 'hover:ring-2 hover:ring-blue-300'
                        }
                      `}
                    >
                      <img
                        src={video.thumbnail}
                        alt={video.title}
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute bottom-0 left-0 right-0 bg-black/50 p-2">
                        <p className="text-white text-sm truncate">
                          {video.title}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Градиенты скролла */}
            <div className="absolute left-0 top-0 bottom-0 w-8 bg-gradient-to-r from-gray-100 to-transparent pointer-events-none z-10" />
            <div className="absolute right-0 top-0 bottom-0 w-8 bg-gradient-to-l from-gray-100 to-transparent pointer-events-none z-10" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoGallery;