import React, { useState } from 'react';
import { useWatchedVideos } from '../hooks/useWatchedVideos';
import YouTubePlayer from './YouTubePlayer';
import { Eraser } from 'lucide-react';

const WatchedVideos = () => {
  const { watchedVideos, clearWatchedVideos } = useWatchedVideos();
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isPlayerVisible, setIsPlayerVisible] = useState(false);

  const handleVideoSelect = (video) => {
    setSelectedVideo(video);
    if (!isPlayerVisible) {
      setIsPlayerVisible(true);
    }
  };

  const formatWatchedDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  };

  if (watchedVideos.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <p className="text-gray-500 text-lg">Вiдео вiдсутнi</p>
      </div>
    );
  }

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex justify-between items-center px-6 py-3">
        <h2 className="text-xl font-semibold">Iсторiя перегляду</h2>
        <button
          onClick={clearWatchedVideos}
          className="px-2 py-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors"
        >
          <Eraser />
        </button>
      </div>

      {/* Плеер */}
      <div className={`
        transition-all duration-500
        ${isPlayerVisible ? 'h-[70%]' : 'h-0'}
        overflow-hidden
      `}>
        {isPlayerVisible && selectedVideo && (
          <div key={selectedVideo.videoId} className="w-full h-full">
            <YouTubePlayer 
              key={selectedVideo.videoId}
              videoId={selectedVideo.videoId}
              title={selectedVideo.title}
            />
          </div>
        )}
      </div>

      {/* Список просмотренных видео */}
      <div className={`
        transition-all duration-500
        ${isPlayerVisible ? 'h-[30%]' : 'h-full'}
      `}>
        <div className="h-full relative">
          <div className="absolute inset-0 overflow-x-auto overflow-y-hidden">
            <div className="min-w-full h-full p-4">
              <div 
                style={{ width: 'max-content' }}
                className="flex gap-4 items-start"
              >
                {watchedVideos.map((video) => (
                  <div
                    key={`${video.id}-${video.watchedAt}`}
                    onClick={() => handleVideoSelect(video)}
                    className={`
                      cursor-pointer
                      transition-all duration-300
                      ${isPlayerVisible ? 'w-[200px] h-[120px]' : 'w-[250px] h-[160px]'}
                      relative
                      rounded-lg
                      overflow-hidden
                      group
                      ${selectedVideo?.id === video.id 
                        ? 'ring-2 ring-blue-500' 
                        : 'hover:ring-2 hover:ring-blue-300'
                      }
                    `}
                  >
                    <img
                      src={video.thumbnail}
                      alt={video.title}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute bottom-0 left-0 right-0 bg-black/50 p-2">
                      <p className="text-white text-sm font-medium truncate">
                        {video.title}
                      </p>
                      <p className="text-white/70 text-xs">
                        Watched: {formatWatchedDate(video.watchedAt)}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WatchedVideos;