import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Play, Pause, SkipForward, SkipBack, Volume2, RefreshCw, Maximize } from 'lucide-react';
import RandomVideosList from './RandomVideosList';

const YouTubePlayer = ({  videoId, title = "Sample Video Title", onVideoSelect }) => {
  const containerRef = useRef(null);
  const playerRef = useRef(null);
  const [error, setError] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showPanels, setShowPanels] = useState(false);
  const [playerState, setPlayerState] = useState({
    isPlaying: false,
    currentTime: 0,
    duration: 0,
    volume: 100,
    isReady: false,
    isLoading: true
  });
  const [fullscreenWatchTime, setFullscreenWatchTime] = useState(0);
  const watchTimerRef = useRef(null);

  const saveToWatched = useCallback(() => {
    const watchedVideos = JSON.parse(localStorage.getItem('watched_videos') || '[]');
    
    // Проверяем, нет ли уже этого видео в списке
    const existingIndex = watchedVideos.findIndex(v => v.videoId === videoId);
    if (existingIndex !== -1) {
      watchedVideos.splice(existingIndex, 1);
    }
    
    // Добавляем видео в начало списка
    watchedVideos.unshift({
      id: `video-${Date.now()}`,
      videoId,
      title,
      thumbnail: '/thumb-base.jpg',
      watchedAt: new Date().toISOString()
    });
  
    // Ограничиваем список 20 последними видео
    if (watchedVideos.length > 20) {
      watchedVideos.pop();
    }
  
    localStorage.setItem('watched_videos', JSON.stringify(watchedVideos));
  }, [videoId, title]);

  const destroyPlayer = useCallback(() => {
    if (playerRef.current) {
      try {
        playerRef.current.destroy();
      } catch (err) {
        console.error('Error destroying player:', err);
      }
      playerRef.current = null;
    }

    // Очищаем div для плеера
    const playerDiv = document.getElementById('youtube-player-div');
    if (playerDiv) {
      while (playerDiv.firstChild) {
        playerDiv.removeChild(playerDiv.firstChild);
      }
    }
  }, []);

  // Инициализация плеера с повторными попытками
  const initPlayer = useCallback(() => {
    if (!videoId) {
      setError('Video ID is required');
      return;
    }

    // Уничтожаем старый плеер
    destroyPlayer();

    // Создаем новый div для плеера
    const playerDiv = document.getElementById('youtube-player-div');
    const newPlayerDiv = document.createElement('div');
    newPlayerDiv.id = `youtube-player-${videoId}`;
    playerDiv.appendChild(newPlayerDiv);

    try {
      new window.YT.Player(`youtube-player-${videoId}`, {
        videoId,
        playerVars: {
          controls: 0,
          disablekb: 1,
          enablejsapi: 1,
          rel: 0,
          showinfo: 0,
          modestbranding: 1,
          iv_load_policy: 3,
          fs: 0,
          playsinline: 1,
          origin: window.location.origin,
          cc_load_policy: 0,
          annotate: 0
        },
        events: {
          onReady: (event) => {
            setPlayerState(prev => ({
              ...prev,
              isReady: true,
              isLoading: false,
              volume: event.target.getVolume(),
              duration: event.target.getDuration()
            }));
            setError(null);
            playerRef.current = event.target;
          },
          onError: (event) => {
            console.error('YouTube Player Error:', event);
            setError('Error loading video. Please try again.');
            setPlayerState(prev => ({ ...prev, isLoading: false }));
          },
          onStateChange: (event) => {
            setPlayerState(prev => ({
              ...prev,
              isPlaying: event.data === window.YT.PlayerState.PLAYING
            }));
          }
        }
      });
    } catch (err) {
      console.error('Player initialization error:', err);
      setError('Failed to initialize player. Please try again.');
      setPlayerState(prev => ({ ...prev, isLoading: false }));
    }
  }, [videoId, destroyPlayer]);

  useEffect(() => {
    if (isFullscreen && playerState.isPlaying) {
      // Запускаем таймер только если видео воспроизводится в полноэкранном режиме
      watchTimerRef.current = setInterval(() => {
        setFullscreenWatchTime(prev => {
          const newTime = prev + 1;
          // Если просмотрено 3 минуты (180 секунд)
          if (newTime >= 180) {
            clearInterval(watchTimerRef.current);
            saveToWatched();
          }
          return newTime;
        });
      }, 1000);
    } else {
      // Очищаем таймер если вышли из полноэкранного режима или поставили на паузу
      if (watchTimerRef.current) {
        clearInterval(watchTimerRef.current);
        watchTimerRef.current = null;
      }
    }
  
    return () => {
      if (watchTimerRef.current) {
        clearInterval(watchTimerRef.current);
      }
    };
  }, [isFullscreen, playerState.isPlaying, saveToWatched]);
  
  // Сбрасываем счетчик при смене видео
  useEffect(() => {
    setFullscreenWatchTime(0);
    if (watchTimerRef.current) {
      clearInterval(watchTimerRef.current);
      watchTimerRef.current = null;
    }
  }, [videoId]);

  // Загрузка YouTube API
  useEffect(() => {
    const loadYouTubeAPI = () => {
      if (window.YT) {
        initPlayer();
        return;
      }

      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      
      tag.onerror = () => {
        setError('Failed to load YouTube API. Please check your connection.');
        setPlayerState(prev => ({ ...prev, isLoading: false }));
      };

      window.onYouTubeIframeAPIReady = initPlayer;
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    };

    loadYouTubeAPI();

    return () => {
      destroyPlayer();
    };
  }, [initPlayer, destroyPlayer]);

  const handlePlayPause = () => {
    if (!playerRef.current || !playerState.isReady) return;
    try {
      if (playerState.isPlaying) {
        playerRef.current.pauseVideo();
      } else {
        playerRef.current.playVideo();
      }
    } catch (err) {
      console.error('Play/Pause error:', err);
      setError('Failed to control playback. Please try again.');
    }
  };

  const handleSeek = (seconds) => {
    if (!playerRef.current || !playerState.isReady) return;
    try {
      const currentTime = playerRef.current.getCurrentTime();
      playerRef.current.seekTo(currentTime + seconds, true);
    } catch (err) {
      console.error('Seek error:', err);
      setError('Failed to seek video. Please try again.');
    }
  };

  const handleVolumeChange = (newVolume) => {
    if (!playerRef.current || !playerState.isReady) return;
    try {
      playerRef.current.setVolume(newVolume);
      setPlayerState(prev => ({ ...prev, volume: newVolume }));
    } catch (err) {
      console.error('Volume change error:', err);
      setError('Failed to change volume. Please try again.');
    }
  };

  const handleRetry = () => {
    setError(null);
    setPlayerState(prev => ({ ...prev, isLoading: true }));
    initPlayer();
  };

  // Обработка полноэкранного режима
  const handleFullscreen = () => {
    if (!containerRef.current) return;

    if (!document.fullscreenElement) {
      // Входим в полноэкранный режим
      if (containerRef.current.requestFullscreen) {
        containerRef.current.requestFullscreen();
      } else if (containerRef.current.webkitRequestFullscreen) {
        containerRef.current.webkitRequestFullscreen();
      } else if (containerRef.current.msRequestFullscreen) {
        containerRef.current.msRequestFullscreen();
      }
    } else {
      // Выходим из полноэкранного режима
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  // Отслеживаем изменения состояния полноэкранного режима
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, []);

  const handleVideoClick = () => {
    if (!isFullscreen) {
      handlePlayPause();
      return;
    }

    if (playerState.isPlaying) {
      playerRef.current?.pauseVideo();
      setShowPanels(true);
    } else {
      playerRef.current?.playVideo();
      setShowPanels(false);
    }
  };

  const handlePlayClick = (e) => {
    e.stopPropagation();
    if (!playerRef.current || !playerState.isReady) return;
    
    if (playerState.isPlaying) {
      playerRef.current.pauseVideo();
      if (isFullscreen) setShowPanels(true);
    } else {
      playerRef.current.playVideo();
      if (isFullscreen) setShowPanels(false);
    }
  };

  const handleRandomVideoSelect = (video) => {
    if (playerRef.current) {
      // Сначала уничтожаем текущий плеер
      playerRef.current.destroy();
      playerRef.current = null;
      
      // Обновляем видео через родительский компонент
      onVideoSelect(video);
      
      // Инициализируем новый плеер с небольшой задержкой
      setTimeout(() => {
        initPlayer(video.videoId);
      }, 100);
    }
  };

  return (
    <div 
      ref={containerRef} 
      className={`w-full max-w-2xl mx-auto bg-white rounded-lg shadow-lg ${
        isFullscreen ? 'fixed inset-0 z-50 max-w-none mx-0 rounded-none' : ''
      }`}
    >
      <div className={`${isFullscreen ? 'h-full' : 'p-6'} relative`}>
        <div className={`relative ${isFullscreen ? 'h-full' : 'aspect-video'} youtube-player-wrapper`}>
          {/* Верхняя панель - только в полноэкранном режиме */}
          {isFullscreen && (
            <div className={`absolute top-0 left-0 right-0 bg-gradient-to-b from-black/70 to-transparent
              transition-transform duration-300 z-20 p-4
              ${showPanels ? 'translate-y-0' : '-translate-y-full'}`}>
              <h2 className="text-white text-xl font-semibold">{title}</h2>
            </div>
          )}

          {/* Нижняя панель - только в полноэкранном режиме */}
          {isFullscreen ? (
            <div className={`absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 to-transparent
              transition-transform duration-300 z-20 
              ${showPanels ? 'translate-y-0' : 'translate-y-full'}`}>
              {/* Контролы */}
              <div className="p-2 flex items-center justify-center gap-4 bg-white">
                <button 
                  className="p-2 rounded-full hover:bg-white/20 transition-colors disabled:opacity-50"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSeek(-10);
                  }}
                  disabled={!playerState.isReady}
                >
                  <SkipBack className="h-6 w-6 text-blue" />
                </button>

                <button 
                  className="p-2 rounded-full hover:bg-white/20 transition-colors disabled:opacity-50"
                  onClick={handlePlayClick}
                  disabled={!playerState.isReady}
                >
                  {playerState.isPlaying ? 
                    <Pause className="h-6 w-6 text-blue" /> : 
                    <Play className="h-6 w-6 text-blue" />
                  }
                </button>

                <button 
                  className="p-2 rounded-full hover:bg-white/20 transition-colors disabled:opacity-50"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSeek(10);
                  }}
                  disabled={!playerState.isReady}
                >
                  <SkipForward className="h-6 w-6 text-blue" />
                </button>

                <div className="flex items-center gap-2">
                  <Volume2 className="h-4 w-4 text-blue" />
                  <input
                    type="range"
                    min="0"
                    max="100"
                    value={playerState.volume}
                    onChange={(e) => handleVolumeChange(parseInt(e.target.value))}
                    disabled={!playerState.isReady}
                    className="w-24"
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>

                <button 
                  className="p-2 rounded-full hover:bg-white/20 transition-colors"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFullscreen();
                  }}
                >
                  <Maximize className="h-6 w-6 text-blue" />
                </button>
              </div>

              {/* Описание */}
              <div className="px-2 text-white bg-white">
                <RandomVideosList 
                  currentVideoId={videoId}
                  onVideoSelect={(video) => {
                    // Используем тот же формат данных, что и в VideoGallery
                    onVideoSelect(video);
                  }}
                />
              </div>
            </div>
          ) : (
            /* Обычные контролы для неполноэкранного режима */
            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex items-center justify-center gap-4 z-20 bg-black/50 px-6 py-3 rounded-full">
              <button 
                className="p-2 rounded-full hover:bg-white/20 transition-colors disabled:opacity-50"
                onClick={() => handleSeek(-10)}
                disabled={!playerState.isReady}
              >
                <SkipBack className="h-4 w-4 text-white" />
              </button>

              <button 
                className="p-2 rounded-full hover:bg-white/20 transition-colors disabled:opacity-50"
                onClick={handlePlayClick}
                disabled={!playerState.isReady}
              >
                {playerState.isPlaying ? 
                  <Pause className="h-4 w-4 text-white" /> : 
                  <Play className="h-4 w-4 text-white" />
                }
              </button>

              <button 
                className="p-2 rounded-full hover:bg-white/20 transition-colors disabled:opacity-50"
                onClick={() => handleSeek(10)}
                disabled={!playerState.isReady}
              >
                <SkipForward className="h-4 w-4 text-white" />
              </button>

              <div className="flex items-center gap-2">
                <Volume2 className="h-4 w-4 text-white" />
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={playerState.volume}
                  onChange={(e) => handleVolumeChange(parseInt(e.target.value))}
                  disabled={!playerState.isReady}
                  className="w-24"
                />
              </div>

              <button 
                className="p-2 rounded-full hover:bg-white/20 transition-colors"
                onClick={handleFullscreen}
              >
                <Maximize className="h-4 w-4 text-white" />
              </button>
            </div>
          )}

          {playerState.isLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-100 z-10">
              <RefreshCw className="w-8 h-8 animate-spin text-gray-500" />
            </div>
          )}

          {error && (
            <div className="absolute inset-0 flex flex-col items-center justify-center bg-gray-100 z-10">
              <p className="text-red-500 mb-4">{error}</p>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={handleRetry}
              >
                Retry
              </button>
            </div>
          )}

          <div id="youtube-player-div" className="w-full h-full" />
          
          {playerState.isReady && (
            <div 
              className="absolute inset-0 z-10 bg-transparent cursor-pointer" 
              onClick={handleVideoClick}
            />
          )}

          <style dangerouslySetInnerHTML={{ __html: `
            .youtube-player-wrapper iframe {
              width: 100% !important;
              height: 100% !important;
              border-radius: ${isFullscreen ? '0' : '0.5rem'};
              pointer-events: none !important;
            }
            
            .ytp-pause-overlay,
            .ytp-endpoint,
            .ytp-related-videos-overlay,
            .ytp-scroll-min,
            .ytp-chrome-top,
            .ytp-chrome-bottom,
            .ytp-gradient-top,
            .ytp-gradient-bottom,
            .ytp-cards-button,
            .ytp-cards-teaser,
            .ytp-ce-element {
              display: none !important;
              opacity: 0 !important;
              pointer-events: none !important;
            }
          `}} />
        </div>
      </div>
    </div>
  );
};

export default YouTubePlayer;