import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import VideoGallery from './components/VideoGallery';
import WatchedVideos from './components/WatchedVideos';
import SearchPage from './components/SearchPage';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <header className="home-header">
          <Header />
        </header>
        <main className="home-content">
          <Routes>
            <Route path="/" element={<VideoGallery />} />
            <Route path="/watched" element={<WatchedVideos />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/:category" element={<VideoGallery />} />
          </Routes>
        </main>
      </div>
    </BrowserRouter>
  );
}

export default App;
