import React, { useState } from 'react';

const VideoAddForm = () => {
  const [formData, setFormData] = useState({
    title: '',
    videoId: '',
    thumbnail: '/thumb-base.jpg', // дефолтное значение
    category: 'homelist'
  });

  const [status, setStatus] = useState({
    message: '',
    isError: false,
    isLoading: false
  });

  const categories = [
    { id: 'homelist', name: 'На головну' },
    { id: 'interestvideos', name: 'Цiкавинки' },
    { id: 'showvideos', name: 'Шоу' },
    { id: 'lernevideos', name: 'Навчання' },
    { id: 'music', name: 'Музика' },
    // Добавьте другие категории по мере необходимости
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ message: '', isError: false, isLoading: true });

    try {
      const response = await fetch('/data/add-video.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          id: `video${Date.now()}` // генерируем уникальный id
        })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to add video');
      }

      setStatus({
        message: 'Video added successfully!',
        isError: false,
        isLoading: false
      });

      // Очищаем форму
      setFormData({
        title: '',
        videoId: '',
        thumbnail: '/thumb-base.jpg',
        category: 'homelist'
      });

    } catch (error) {
      setStatus({
        message: error.message,
        isError: true,
        isLoading: false
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Если изменяется videoId, автоматически обновляем thumbnail
    if (name === 'videoId') {
      setFormData(prev => ({
        ...prev,
        [name]: value,
        thumbnail: value ? `https://i.ytimg.com/vi/${value}/hq720.jpg` : '/thumb-base.jpg'
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  return (
    <div className="max-w mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6">Add New Video</h2>
      
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Title
          </label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            YouTube Video ID
          </label>
          <input
            type="text"
            name="videoId"
            value={formData.videoId}
            onChange={handleChange}
            required
            placeholder="e.g., dQw4w9WgXcQ"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Thumbnail Preview
          </label>
          <img 
            src={formData.thumbnail} 
            alt="Thumbnail preview" 
            className="w-48 h-auto"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Category
          </label>
          <select
            name="category"
            value={formData.category}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {categories.map(category => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        {status.message && (
          <div className={`p-3 rounded ${
            status.isError ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'
          }`}>
            {status.message}
          </div>
        )}

        <button
          type="submit"
          disabled={status.isLoading}
          className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-white font-medium 
            ${status.isLoading 
              ? 'bg-blue-300 cursor-not-allowed' 
              : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
            }`}
        >
          {status.isLoading ? 'Adding...' : 'Add Video'}
        </button>
      </form>
    </div>
  );
};

export default VideoAddForm;