import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import ModalView from './ModalView';
import { BookMarked, History, House, Music4, Projector, Search, Sparkles } from 'lucide-react';

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const categories = [
    { id: 'watched', name: 'Iсторiя', path: '/watched', icon: <History /> },
    { id: 'homelist', name: 'Home', path: '/', icon: <House /> },
    { id: 'interestvideos', name: 'Цiкавинки', path: '/interestvideos', icon: <Sparkles /> },
    { id: 'showvideos', name: 'Шоу', path: '/showvideos', icon: <Projector /> },
    { id: 'lernevideos', name: 'Навчання', path: '/lernevideos', icon: <BookMarked /> },
    { id: 'music', name: 'Музика', path: '/music', icon: <Music4 /> },
  ];

  // Находим активную категорию
  const activeCategory = categories.find(category => category.path === location.pathname);

  return (
    <div className="flex flex-col justify-between h-full">
      <div className='flex flex-row items-center h-full justify-between mx-4 mt-3'>
        <div className="logo bg-blue-100/50 rounded-lg p-2">
          <img src="./logo-site.png" alt="Logo" className='w-[130px]' />
        </div>
        <nav className="flex items-center justify-between px-6">
          <div className="flex space-x-6">
            {categories.map(category => (
              <NavLink
                key={category.id}
                to={category.path}
                className={({ isActive }) => `
                  px-3 py-3 rounded-full transition-colors
                  ${isActive 
                    ? 'border-solid border-3 border-blue-700 text-blue-700 text-4xl' 
                    : 'text-gray-700 hover:bg-blue-100'
                  }
                `}
              >
                {category.icon}
              </NavLink>
            ))}
          </div>
        </nav>
        <div className='flex flex-row'>
          <button
            onClick={() => navigate('/search')}
            className="p-2 rounded-full hover:bg-gray-100 transition-colors mr-4"
          >
            <Search className="h-6 w-6" />
          </button>
          <ModalView />
        </div>
      </div>
      
      {/* Название активной категории */}
      <div className="text-center mb-2">
        {activeCategory && (
          <span className="text-blue-700 font-medium text-xl">
            {activeCategory.name}
          </span>
        )}
      </div>
    </div>
  );
};

export default Header;