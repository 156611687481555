import React, { useState, useEffect } from 'react';
import { Search as SearchIcon, ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import YouTubePlayer from './YouTubePlayer';

const SearchPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [allVideos, setAllVideos] = useState([]);
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isPlayerVisible, setIsPlayerVisible] = useState(false);
  const navigate = useNavigate();

  // Загружаем все видео из JSON
  useEffect(() => {
    const loadVideos = async () => {
      try {
        const response = await fetch('/data/videos.json');
        const data = await response.json();
        
        // Собираем все видео из всех категорий в один массив
        const videos = Object.entries(data).reduce((acc, [category, videos]) => {
          const videosWithCategory = videos.map(video => ({
            ...video,
            category
          }));
          return [...acc, ...videosWithCategory];
        }, []);
        
        setAllVideos(videos);
        setIsLoading(false);
      } catch (error) {
        console.error('Error loading videos:', error);
        setIsLoading(false);
      }
    };

    loadVideos();
  }, []);

  // Фильтруем видео при изменении поискового запроса
  useEffect(() => {
    if (!searchQuery.trim()) {
      setFilteredVideos([]);
      return;
    }

    const query = searchQuery.toLowerCase();
    const results = allVideos.filter(video => 
      video.title.toLowerCase().includes(query)
    );
    setFilteredVideos(results);
  }, [searchQuery, allVideos]);

  const handleVideoSelect = (video) => {
    setSelectedVideo(video);
    if (!isPlayerVisible) {
      setIsPlayerVisible(true);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Шапка поиска */}
      <div className="sticky top-0 bg-white shadow-md z-10">
        <div className="flex items-center gap-4 p-4">
          <button
            onClick={() => navigate(-1)}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <ArrowLeft className="h-6 w-6" />
          </button>
          
          <div className="relative flex-1">
            <SearchIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search videos..."
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              autoFocus
            />
          </div>
        </div>
      </div>

      {/* Контент */}
      <div className="p-4">
        {/* Плеер */}
        <div className={`
          transition-all duration-500
          ${isPlayerVisible ? 'h-[500px] opacity-100 mb-6' : 'h-0 opacity-0'}
          overflow-hidden
        `}>
          {isPlayerVisible && selectedVideo && (
            <div key={selectedVideo.videoId} className="w-full h-full">
              <YouTubePlayer 
                key={selectedVideo.videoId}
                videoId={selectedVideo.videoId}
                title={selectedVideo.title}
              />
            </div>
          )}
        </div>

        {/* Результаты поиска */}
        {isLoading ? (
          <div className="flex justify-center items-center h-40">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
          </div>
        ) : searchQuery.trim() === '' ? (
          <div className="text-center text-gray-500 py-10">
            Start typing to search videos
          </div>
        ) : filteredVideos.length === 0 ? (
          <div className="text-center text-gray-500 py-10">
            No videos found for "{searchQuery}"
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {filteredVideos.map((video) => (
              <div
                key={video.id}
                onClick={() => handleVideoSelect(video)}
                className="cursor-pointer group bg-white rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow"
              >
                <div className="relative aspect-video">
                  <img
                    src={video.thumbnail}
                    alt={video.title}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-20 transition-opacity" />
                </div>
                <div className="p-3">
                  <h3 className="font-medium text-gray-900 truncate">
                    {video.title}
                  </h3>
                  <p className="text-sm text-gray-500 mt-1">
                    {video.category}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchPage;