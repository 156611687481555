// hooks/useWatchedVideos.js
import { useState, useEffect } from 'react';

const WATCHED_VIDEOS_KEY = 'watched_videos';

export const useWatchedVideos = () => {
  const [watchedVideos, setWatchedVideos] = useState([]);

  // Только загружаем данные из localStorage
  useEffect(() => {
    const stored = localStorage.getItem(WATCHED_VIDEOS_KEY);
    if (stored) {
      setWatchedVideos(JSON.parse(stored));
    }
  }, []);

  // Функция очистки истории
  const clearWatchedVideos = () => {
    localStorage.removeItem(WATCHED_VIDEOS_KEY);
    setWatchedVideos([]);
  };

  return {
    watchedVideos,
    clearWatchedVideos
  };
};