import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import VideoAddForm from './VideoAddForm';
import { UserPen } from 'lucide-react';

function MyVerticallyCenteredModal({ show, onHide }) {
    const [password, setPassword] = useState('');
    const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);

    const handlePasswordSubmit = () => {
        if (password === '99999') { // Пароль для доступа
            setIsPasswordCorrect(true);
        } else {
            setIsPasswordCorrect(false);
            onHide(); // Закрываем модальное окно при неверном пароле
        }
    };

    // Сброс состояний при закрытии модального окна
    const handleClose = () => {
        setPassword('');
        setIsPasswordCorrect(false);
        onHide();
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add new Video
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!isPasswordCorrect ? (
                    <>
                        <p>Введите пароль для доступа:</p>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Введите 5-значный пароль"
                            className="form-control"
                        />
                        <Button onClick={handlePasswordSubmit} className="mt-3">
                            Проверить пароль
                        </Button>
                    </>
                ) : (
                    <VideoAddForm />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

const ModalView = () => {
    const [modalShow, setModalShow] = useState(false);

    return (
        <>
            <Button className='rounded-circle p-2' variant="outline-secondary" onClick={() => setModalShow(true)}>
                <UserPen />
            </Button>

            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
};

export default ModalView;
