import React, { useState, useEffect } from 'react';
import { Play } from 'lucide-react';

const RandomVideosList = ({ currentVideoId, onVideoSelect }) => {
  const [randomVideos, setRandomVideos] = useState([]);

  useEffect(() => {
    const loadRandomVideos = async () => {
      try {
        const response = await fetch('/data/videos.json');
        const data = await response.json();
        
        // Собираем все видео из всех категорий
        const allVideos = Object.entries(data).reduce((acc, [_, categoryVideos]) => {
          return [...acc, ...categoryVideos];
        }, []);

        // Фильтруем текущее видео
        const availableVideos = allVideos.filter(video => video.videoId !== currentVideoId);
        
        // Получаем 4 случайных видео
        const shuffled = [...availableVideos].sort(() => 0.5 - Math.random());
        const selected = shuffled.slice(0, 4);
        
        setRandomVideos(selected);
      } catch (error) {
        console.error('Error loading random videos:', error);
      }
    };

    loadRandomVideos();
  }, [currentVideoId]);

  return (
    <div className="w-full">
      <h3 className="text-black text-lg mb-2 font-medium">Recommended videos</h3>
      <div className="flex flex-nowrap items-center gap-4 overflow-x-auto">
        {randomVideos.map((video) => (
          <div
            key={video.videoId}
            onClick={() => onVideoSelect({
              id: video.videoId, // Используем те же поля, что и в VideoGallery
              videoId: video.videoId,
              title: video.title,
              thumbnail: video.thumbnail
            })}
            className={`
              cursor-pointer
              w-[200px] h-[120px]
              relative
              rounded-lg
              overflow-hidden
              transition-all
              duration-300
              shadow-lg
              hover:ring-2 hover:ring-blue-300
            `}
          >
            <img
              src={video.thumbnail}
              alt={video.title}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black/50 opacity-0 hover:opacity-100 transition-opacity flex items-center justify-center">
              <Play className="h-8 w-8 text-white" />
            </div>
            <div className="absolute bottom-0 left-0 right-0 bg-black/50 p-2">
              <p className="text-white text-sm truncate">
                {video.title}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RandomVideosList;